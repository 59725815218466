html, body, #root {
  margin: 0;
  height: 100%; }

#main-container .container {
  margin-top: 40px;
  padding-bottom: 80px; }

@media screen and (min-width: 992px) {
  .media-modal {
    max-width: 1920px; } }

.dropzone {
  width: 100%;
  height: 160px;
  background: #F4F4F4;
  border-radius: 8px;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  font-size: 22px;
  border: 3px dashed #CCCCCC; }
  .dropzone:hover {
    border-color: black; }

.media {
  position: relative;
  opacity: 0.7;
  height: 200px; }
  .media .delete-container {
    display: none;
    position: absolute;
    top: -10px;
    right: -10px;
    cursor: pointer;
    z-index: 100; }
  .media:hover {
    opacity: 1;
    cursor: pointer; }
    .media:hover .delete-container {
      display: block; }

td .form-check-input, th .form-check-input {
  position: relative;
  margin: 0; }

.react-tags__search {
  z-index: 100; }

.react-tags__suggestions {
  overflow-y: scroll;
  overflow-x: hidden;
  z-index: 1000;
  max-height: 500px; }

.__search-input-container {
  position: relative; }

.__search-input,
.__search-input:focus,
.__search-input:active {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16), 0 0 0 1px rgba(0, 0, 0, 0.08);
  border: honeydew;
  display: block;
  width: 100%;
  padding: 16px;
  font-size: 16px;
  border-radius: 2px;
  outline: none; }

.__clear-button,
.__clear-button:active,
.__clear-button:focus {
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
  background: transparent;
  border: none;
  outline: none;
  font-weight: 600;
  color: #999; }

.__autocomplete-container {
  z-index: 10000;
  position: absolute;
  top: 48px;
  left: 0;
  right: 0;
  border-bottom: honeydew;
  border-left: honeydew;
  border-right: honeydew;
  border-top: 1px solid #e6e6e6;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  border-radius: 0 0 2px 2px; }

.__suggestion-item {
  padding: 8px;
  text-align: left;
  background-color: #fff;
  cursor: pointer; }

.__suggestion-item--active {
  background-color: #fafafa; }
